export const ApiConfig = {
  basePath: 'https://www.api.contractor.eparconnectserver.com',
  connectApiBasePath: 'https://www.eparconnectserver.com',
  stage: 'qa',
  mapKey: 'AIzaSyDpxsfwMfngJ-Nq_ombIxQuv1x18hAqLBA'
}

export const AwsConfig = {
  cognitoUserPoolId: 'us-west-2_XWcnWx3Mw',
  userPoolWebClientId: '3p5uk7inbp03lse1pkubkbnsla',
  cognitoIdentityPoolId: 'us-west-2:331ecf01-6b84-4855-ac89-2d56fe129ec9',
  awsRegion: 'us-west-2'
}